import { load, loop } from 'product-icon';
import productHeroNav from 'product-hero-nav';
import transitionEnd from 'transition-end';
import vars from 'variables';
import { $, $$ } from 'query-selector';

// TO DO:
// - Refactor anims with for of / functions
// - import() product icons so that anime.js is async module

export default async (skipIntro = true) => {
  const hero = $('.js-product-hero');
  if (!hero) return;

  // Skip anims if legacy browser
  if (vars.browser.legacy) {
    hero.classList.remove('u-anim-hidden');
    hero.style.setProperty('transition', `opacity 500ms ${vars.ease.fade}`);
    return;
  }

  // Skip intro
  if (skipIntro) {
    // No need to animate in since <main> is animated
    hero.classList.remove('u-anim-hidden');

    const icons = $$('.js-product-icon', hero);
    for (const icon of icons) loop(icon);

    return;
  }

  // Enable swipe navigation
  // productHeroNav();

  // Get elements
  const heroIcon = $('.js-product-hero-icon');
  const nextIcon = $('.js-product-next-icon');
  const prevIcon = $('.js-product-prev-icon');
  const nextLink = $('.js-product-next-link');
  const prevLink = $('.js-product-prev-link');

  // Prepare links and icons
  hero.classList.remove('u-anim-hidden');

  nextLink.style.setProperty('opacity', '0');
  prevLink.style.setProperty('opacity', '0');
  nextLink.style.setProperty('transform', 'translateY(1.5em)');
  prevLink.style.setProperty('transform', 'translateY(1.5em)');

  heroIcon.style.setProperty('opacity', '0');
  nextIcon.style.setProperty('opacity', '0');
  prevIcon.style.setProperty('opacity', '0');
  heroIcon.style.setProperty('transform', 'translateY(3em)');
  nextIcon.style.setProperty('transform', 'translateX(-50%) translateY(3em)');
  prevIcon.style.setProperty('transform', 'translateX(50%) translateY(3em)');

  const forceLayout = hero.offsetWidth;

  // Animate icons
  const loadAnims = [];

  load(heroIcon);
  heroIcon.removeAttribute('style');
  heroIcon.style.setProperty(
    'transition',
    `opacity 500ms ${vars.ease.fade}, transform 1000ms ${vars.ease.out}`
  );
  loadAnims.push(transitionEnd(heroIcon, 'transform'));

  load(nextIcon, 300);
  nextIcon.style.setProperty('opacity', '1');
  nextIcon.style.setProperty('transform', 'translateX(-50%)');
  nextIcon.style.setProperty(
    'transition',
    `opacity 500ms ${vars.ease.fade} 300ms, transform 1000ms ${
      vars.ease.out
    } 300ms`
  );
  loadAnims.push(transitionEnd(nextIcon, 'transform'));

  load(prevIcon, 300);
  prevIcon.style.setProperty('opacity', '1');
  prevIcon.style.setProperty('transform', 'translateX(50%)');
  prevIcon.style.setProperty(
    'transition',
    `opacity 500ms ${vars.ease.fade} 300ms, transform 1000ms ${
      vars.ease.out
    } 300ms`
  );
  loadAnims.push(transitionEnd(prevIcon, 'transform'));

  // Delay before anim to end state
  loadAnims.push(new Promise(resolve => setTimeout(resolve, 1500)));

  await Promise.all(loadAnims);

  // Animate icon positions
  prevIcon.removeAttribute('style');
  nextIcon.removeAttribute('style');
  prevIcon.style.setProperty(
    'transition',
    `transform 1000ms ${vars.ease.inOut}`
  );
  nextIcon.style.setProperty(
    'transition',
    `transform 1000ms ${vars.ease.inOut}`
  );

  // Fade in Links
  prevLink.removeAttribute('style');
  nextLink.removeAttribute('style');
  prevLink.style.setProperty(
    'transition',
    `transform 1000ms ${vars.ease.out} 400ms, opacity 700ms ${
      vars.ease.fade
    } 400ms`
  );
  nextLink.style.setProperty(
    'transition',
    `transform 1000ms ${vars.ease.out} 400ms, opacity 700ms ${
      vars.ease.fade
    } 400ms`
  );
};
