import parallax from 'parallax';
import vars from 'variables';
import { $ } from 'query-selector';

export default () => {
  const hero = $('.js-hero');
  if (!hero) return;

  const img = $('figure', hero);
  const offset = vars.browser.wide() ? 10 : 6;

  parallax([
    {
      reference: hero,
      target: img,
      top: true,
      transform: [
        {
          type: 'translateY',
          value: offset,
          unit: 'rem',
          origin: 'start',
          ease: 'in'
        }
      ]
    }
  ]);
};
