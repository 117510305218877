import anime from 'anime';
import vars from 'variables';
import points from 'product-icon-points';
import { $, $$ } from 'query-selector';

export function loop(icon) {
  if (!icon || vars.browser.edge) return;

  const type = $('svg', icon).id;
  const polygons = $$('polygon', icon);

  polygons.forEach((polygon, i) => {
    anime({
      targets: polygon,
      points: [...points[type].loop[i], points[type].initial[i]],
      duration: 30000,
      easing: 'cubicBezier(0.25, 0, 0.75, 1)',
      loop: true
    });
  });
}

export async function load(icon, delay = 0) {
  if (!icon || vars.browser.edge) return;

  const type = $('svg', icon).id;
  const polygons = $$('polygon', icon);

  const anims = [];

  polygons.forEach((polygon, i) => {
    const anim = anime({
      targets: polygon,
      points: [points[type].load[i], points[type].initial[i]],
      duration: 2000,
      delay: delay,
      easing: 'cubicBezier(0.23, 1, 0.32, 1)'
    });

    anims.push(anim.finished);
  });

  await Promise.all(anims);
  loop(icon);
}

export function reset(icon, duration = 500) {
  if (!icon || vars.browser.edge) return;

  const type = $('svg', icon).id;
  const polygons = $$('polygon', icon);

  polygons.forEach((polygon, i) => {
    anime.remove(polygon);

    anime({
      targets: polygon,
      points: points[type].initial[i],
      duration: duration,
      easing: 'cubicBezier(0.55, 0, 0.1, 1)'
    });
  });
}
