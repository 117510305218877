import productHero from 'product-hero';
import animation from 'animation';
import { $ } from 'query-selector';

// no async/await so modules can load in parallel
export default (skipProductIntro = false) => {
  // immediately
  animation();
  productHero(skipProductIntro);

  // async
  if ($('.js-team-filter')) import('team-filter').then(m => m.default());
  if ($('.js-profile-text')) import('profile').then(m => m.default());
  if ($('.js-client-login')) import('client-login').then(m => m.default());
  if ($('.js-product-slider')) import('product-slider').then(m => m.default());
  if ($('.js-map')) import('map').then(m => m.default());
};
