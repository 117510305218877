export default {
  reinsurance: {
    initial: [
      '5,120 183,23 171,297',
      '335,242 171,297 183,23',
      '183,23 171,297 18,93',
      '183,23 306,167 171,297'
    ],
    load: [
      '171,297 183,45 171,297',
      '171,297 171,297 183,45',
      '183,23 171,297 183,90',
      '183,23 184,45 171,297'
    ],
    loop: [
      ['5,138 173,13 180,296', '18,133 183,13 160,306'],
      ['320,241 180,296 173,13', '335,254 160,306 183,13'],
      ['173,13 180,296 18,73', '183,13 160,306 5,94'],
      ['173,13 320,167 180,296', '183,13 310,150 160,306']
    ]
  },
  underwriting: {
    initial: [
      '298, 185 151,3 118,261',
      '312,229 298,185 111,73 64,272',
      '28,233 64,272 312,229',
      '64,272 53,317 312,229'
    ],
    load: [
      '300,213 29,207 142,257 312,226',
      '312,226 300,213 29,207 79,270',
      '29,207 79,270 312,226',
      '79,270 29,207 312,226'
    ],
    loop: [
      ['301,195 120,20 117,260', '307,205 180,13 117,260'],
      ['310,220 301,195 71,82 83,271', '322,248 307,205 82,91 63,251'],
      ['28,242 83,271 310,220', '51,205 63,251 322,248'],
      ['83,271 57,307 310,220', '63,251 48,292 322,248']
    ]
  },
  audits: {
    initial: [
      '286,103 305,164 188,308',
      '90,33 35,67 188,308 286,103 246,28',
      '152,13 90,33 246,28',
      '152,13 221,83 246,28'
    ],
    load: [
      '288,108 250,174 188,308',
      '212,175 188,308 188,308 288,108 288,108',
      '212,175 212,175 288,108',
      '212,175 229,162 288,108'
    ],
    loop: [
      ['279,82 296,164 177,307', '296,116 301,182 200,294'],
      [
        '96,33 54,61 177,307 279,82 251,35',
        '74,28 26,78 200,294 296,116 230,28'
      ],
      ['161,6 96,33 251,35', '141,9 74,28 230,28'],
      ['161,6 219,64 251,35', '141,9 186,60 230,28']
    ]
  }
};
